@import "../util/style.scss";

.wrapper {
  width: 100%;
  height: --100vh;
  @include row(none, none, 0px);
  flex-direction: row-reverse;
  position: absolute;
  left: 0;
  top: 0;
  background-color: $col-w;
}

.frame {
  &-content {
    height: --100vh;
    flex: 1;
    box-sizing: border-box;
    padding: 5rem 2rem;
    // background-color: $col-g95;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.25rem;
      height: 0.25rem;
      &-thumb {
        height: 10%;
        background-color: $col-bgd-g9;
        border-radius: 10px;
        &:hover {
          background-color: $col-bgd-g8;
        }
      }
      &-track {
        // background-color: $col-bgd-g9;
      }
      &:hover {
        width: 0.5rem;
      }
    }
  }
}

.text {
  &-section {
    font-size: 2rem;
    font-weight: $font-e;
    text-align: left;

    word-break: keep-all;
    // color: $col-1;
  }
  &-subsection {
    font-size: 1.75rem;
    font-weight: $font-e;
    text-align: left;

    word-break: keep-all;
    color: $col-g5;
  }
}
