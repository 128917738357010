@import "../util/style.scss";

.wrapper {
  width: 100%;
  height: __100vh;
  @include row(none, none, 0px);
  flex-direction: row-reverse;
  position: absolute;
  left: 0;
  top: 0;
  background-color: $col-w;
}

.frame {
  &-content {
    height: --100vh;
    flex: 1;
    box-sizing: border-box;
    padding: 5rem 2rem;
    // background-color: $col-g95;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.25rem;
      height: 0.25rem;
      &-thumb {
        height: 10%;
        background-color: $col-bgd-g9;
        border-radius: 10px;
        &:hover {
          background-color: $col-bgd-g8;
        }
      }
      &:hover {
        width: 0.5rem;
      }
    }
  }
}

.title {
  @include txt-head;
  font-size: 32px;
  margin-top: 60px;
  color: $col-bgd-g5;
}

.wrapper > .title {
  &:first-child {
    margin-top: 0px;
    color: $col-bgd-2;
  }
  &:last-child {
    margin-top: 200px;
    color: $col-bgd-2;
  }
}

.event {
  @include txt-head;
  background-color: $col-bgd-g8;
  padding: 15px;
  color: $col-txt-w;
  cursor: pointer;
  &:hover {
    background-color: $col-bgd-g9;
  }
  &:active {
    background-color: $col-bgd-g8;
  }
}

.relative {
  position: relative;
}

.background {
  width: 100%;
  @include column(auto, auto, 0px);
  &-white {
    width: 80%;
    @include column(none, none, 30px);
    padding-bottom: 30px;
    background-color: $col-bgd-w;
  }
  &-dark {
    width: 80%;
    @include column(none, none, 30px);
    padding-bottom: 30px;
    background-color: $col-bgd-g3;
  }
}

.map-wrapper {
  width: 80%;
  height: 80vh;
  overflow: hidden;
}

.modal-area {
  position: relative;
  z-index: 30;
}
