@import "../util/style.scss";

.wrapper {
  @include row(auto, auto, 1rem);
  justify-content: space-between;
  padding: 1rem;
  min-height: 1rem;
  border-radius: 0.5rem;
  background-color: $col-w;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  overflow: hidden;
  &.style {
    &-default {
      background-color: $col-g2;
    }
  }
  &:hover {
    background-color: $col-g1;
  }
  cursor: pointer;
}

.frame {
  &-icon {
    width: 1.8rem;
    height: 0.8rem;
    border-radius: 1rem;
    border: 0.1rem solid $col-w;
    font-weight: bold;
    @include row(auto, auto, 0);
    justify-content: center;
    align-items: flex-start;
    color: $col-w;
    line-height: 1.7;
    // box-shadow: 0 0 5rem $col-w;
    &.style {
      &-warning {
        color: $col-y;
        border-color: $col-y;
        box-shadow: 0 0 10rem $col-y;
      }
      &-error {
        color: $col-r;
        border-color: $col-r;
        box-shadow: 0 0 10rem $col-r;
      }
      &-success {
        color: $col-g;
        border-color: $col-g;
        box-shadow: 0 0 10rem $col-g;
      }
      &-info {
        color: $col-b;
        border-color: $col-b;
        box-shadow: 0 0 10rem $col-b;
      }
    }
  }
  &-text {
    color: $col-g8;
    font-weight: $font-b;
  }
}

.action {
  font-weight: $font-e;
  color: $col-w;
}

@media (max-width: 600px) {
  .wrapper {
    @include row(auto, auto, 1rem);
    width: calc(100vw - 4rem);
    justify-content: space-between;
    min-width: 0px;
    min-height: 0.75rem;
    height: 0.9rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: $col-1;
    box-shadow: 0 0 0.5rem $col-g8;
    overflow: hidden;
    &.style {
      &-default {
        border-color: $col-1;
      }
    }
    &:hover {
      background-color: $col-1;
    }
    cursor: pointer;
  }
  .frame {
    &-icon {
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 1rem;
      font-weight: bold;
      @include row(auto, auto, 0);
      justify-content: center;
      align-items: flex-start;
      color: $col-w;
      line-height: 1.05;
    }
    &-text {
      color: $col-w;
      font-weight: $font-r;
    }
  }
  .action {
    color: $col-w;
    font-weight: $font-b;
  }
}
