@import "../util/style.scss";

.wrapper {
  @include column(100%, auto, 0px);
  justify-content: space-between;
  width: 4rem;
  height: var(--new100vh);
  border-right: 1.5px solid $col-g8;
  background-color: $col-w;
  z-index: 2;
  overflow: hidden;
}

.frame {
  &-col {
    @include column(auto, auto, 0px);
  }
  &-logo {
    height: 5rem;
    width: 4rem;
    @include column(auto, auto, 0.2rem);
    justify-content: center;
  }
  &-main {
    @include column(auto, auto, 0px);
    width: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    > * {
      width: 100%;
    }
    max-height: calc(calc(var(--vh, 1vh) * 100) - 9rem);
  }
  &-footer {
    @include column(auto, auto, 0px);
    width: 100%;
    height: 4rem;
  }
  &-button {
    position: relative;
    height: 6rem;
    width: 100%;
    overflow: hidden;
    @include column(auto, auto, 0px);
    justify-content: center;
    // align-items: flex-start;
    cursor: pointer;
    &:hover {
      background-color: $col-g95;
    }
    &.emph {
      background-color: $col-g3;
    }
    transition: all 0.3s;
  }
}

.title {
  // position: absolute;
  // top: 4rem;

  text-align: center;
  color: $col-g5;
  width: calc(100% - 1rem);
  font-size: 0.75rem;
  line-height: 1.2;
  padding: 0rem 0.5rem;
  word-break: keep-all;
  white-space: normal;
  padding-bottom: 0.5rem;
  &.emph {
    color: $col-w;
  }
}

.logo {
  width: 2rem;
  &-title {
    font-family: "Noto Sans KR";
    font-size: 0.75rem;
    color: $col-1;
    font-weight: bolder;
  }
}

@media (max-height: 600px) {
  .frame {
    &-button {
      height: 4.5rem;
    }
    &-logo {
    }
  }

  .title {
    // position: absolute;
    // top: 4rem;
    font-family: "MalgunGothic";
    font-size: 0.6rem;
  }
}

@media (max-width: 600px) {
  .wrapper {
    width: 100%;
    border-right: 0px solid $col-g8;
    background-color: $col-w;
  }
  .frame {
    &-button {
      padding-left: 0.5rem;
      flex-direction: row;
      height: 3rem;
      gap: 0.5rem;
      &:hover {
        background-color: $col-w;
      }
      &.emph {
        background-color: $col-g95;
      }
    }
  }

  .title {
    // position: absolute;
    // top: 4rem;
    text-align: left;
    padding: 0;
    font-family: "NanumSquare";
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-weight: $font-b;
    color: $col-g3;
    &.emph {
      color: $col-1;
      // background-color: $col-g95;
    }
  }
}
