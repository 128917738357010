@import "../util/style.scss";

.wrapper {
  position: relative;
}

.frame {
  &-switch {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 2.4rem;
    height: 1.2rem;
    background: #fff;
    border: 1.5px solid $col-1;
    border-radius: 20px;
    transition: 0.2s;
    &:hover {
      background: $col-g95;
    }
    &.off {
      border-color: $col-g8;
    }
    overflow: hidden;
  }
  &-multiple-switch {
    position: relative;
    &.vertical {
      @include column(auto, auto, 0rem);
    }
    &.horizontal {
      @include row(auto, auto, 0.3rem);
    }
    padding: 0.2rem 0.3rem;
    // border: 1.5px solid $col-g9;
    background-color: $col-g95;
    border-radius: 0.5rem;
    overflow: hidden;
    flex-wrap: wrap;
    &.color {
      &-white {
        background-color: $col-w;
      }
    }
    &.align {
      &-right {
        align-items: flex-end;
      }
    }
  }
  &-button {
    position: absolute;
    top: 0.1rem;
    left: 1.3rem;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 20px;
    background: $col-1;
    transition: 0.2s;

    &.off {
      background-color: $col-w;
      left: 0.1rem;
      box-shadow: 0 0 0.5rem $col-g6;
    }
  }
  &-multiple-button {
    font-weight: bold;
    white-space: nowrap;
    color: $col-g2;
    padding: 0.4rem 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: 0.2s;
    max-width: calc(100% - 0.6rem);
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    &:hover {
      background: $col-w;
    }

    &.color {
      &-white {
        &:hover {
          background: $col-g95;
        }
      }
    }
    &.on {
      // box-shadow: 0 0 0.5rem $col-g7;
      background-color: $col-1;
      // font-weight: bold;
      color: $col-w;
      pointer-events: none;
      &.unselectable {
        pointer-events: all;
        cursor: pointer;
      }
      &:hover {
        background: $col-1;
      }
    }
  }
  &-single-button {
    font-weight: bold;
    color: $col-g2;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1.5px solid $col-g8;
    cursor: pointer;
    transition: 0.2s;
    max-width: calc(100% - 0.6rem);
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    &:hover {
      background: $col-g95;
    }
    &.on {
      // box-shadow: 0 0 0.5rem $col-g7;
      border-color: $col-1;
      background-color: $col-1;
      // font-weight: bold;
      color: $col-w;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.title {
  padding: 8px 13px 0 13px;
  font-size: 14px;
  font-weight: bold;
  color: $col-g5;
  &.align {
    &-right {
      text-align: right;
    }
  }
}

@media (max-width: 600px) {
  .wrapper {
    width: 100%;
  }
  .frame {
    &-switch {
      position: relative;
      cursor: pointer;
      display: inline-block;
      width: 4.075rem;
      height: 2.025rem;
      background: #fff;
      border: 1.5px solid $col-1;
      border-radius: 20px;
      transition: 0.2s;
      &:hover {
        background: rgba(0, 0, 0, 0);
      }
      &.off {
        border-color: $col-g7;
      }
    }
    &-multiple-switch {
      position: relative;
      &.vertical {
        @include column(auto, auto, 0.3rem);
      }
      &.horizontal {
        @include row(auto, auto, 0.3rem);
      }
      padding: 0rem 0rem;
      border: 1.5px solid $col-g8;
      background-color: $col-w;
      border-radius: 0.5rem;
      overflow: hidden;
      flex-wrap: wrap;
      width: 100%;
    }
    &-button {
      position: absolute;
      top: 0.15rem;
      left: 2.2rem;
      display: inline-block;
      width: 1.7rem;
      height: 1.7rem;
      border-radius: 20px;
      background: $col-1;
      transition: 0.2s;
      &.off {
        background-color: $col-w;
        left: 0.15rem;
      }
    }
    &-multiple-button {
      padding: 0.75rem 0.5rem;
      width: 3rem !important;
      height: 2rem !important;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: 0.2s;
      max-width: calc(100% - 0.6rem);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 0.75rem;
      &:hover {
        background: $col-g95;
      }
      &.on {
        // box-shadow: 0 0 0.5rem $col-g7;
        background-color: $col-1;
        // font-weight: bold;
        color: $col-w;
        pointer-events: none;
      }
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
