@import "../util/style.scss";

.wrapper {
  position: relative;
  @include column(auto, auto, 0rem);
  align-items: flex-start;
  transition: all 0.3s;
  width: fit-content;
  pointer-events: all;
  &.show {
    overflow: visible;
  }
  &.hide {
    overflow: hidden;
  }
}

.shape {
  &-default {
    border-radius: 0.5rem;
  }
  &-rectangle {
    border-radius: 0;
  }
}

.solid {
  background-color: $col-w;
  border: 1.5px solid $col-g8;
  // @include shd-1;
}

.clickable {
  &.transparent {
    cursor: pointer;
    &:hover {
      background-color: $col-g95;
      // @include shd-1;
    }
  }
  &.solid {
    cursor: pointer;
    &:hover {
      background-color: $col-g95;
      // @include shd-2;
    }
  }
  &.naked {
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }
}

.full {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.naked {
  border-width: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  //
}

@media (max-width: 600px) {
  .transparent {
    cursor: pointer;
    background-color: $col-w;
    border: 1.5px solid $col-g8;
  }
  .clickable {
    &.transparent {
      cursor: pointer;
      &:focus {
        background-color: $col-g95;
        // @include shd-1;
      }
    }
  }
}
