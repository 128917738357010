@import "../util/style.scss";

.wrapper {
  width: 100vw;
  height: var(--new100vh);
  overflow: hidden;
  @include row(none, none, 0px);
  flex-direction: row-reverse;
  position: absolute;
  left: 0;
  top: 0;
  background-color: $col-w;
}

.frame {
  &-content {
    position: relative;
    height: var(--new100vh);
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;
  }
  &-iframe {
    position: relative;
    min-height: 100%;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;
  }
  &-button {
    @include column(auto, auto, 0.5rem);
    align-items: flex-start;
    pointer-events: none !important;
    & > * {
      pointer-events: all;
    }
    width: fit-content;
  }
  &-button-field {
    position: absolute;
    top: 4rem;
    width: calc(100% - 2rem);
    height: calc(var(--new100vh) - 10rem);
    padding: 1rem;
  }
  &-button {
    width: 3rem;
    height: 3rem;
  }
  &-element-stack {
    position: fixed;
    display: none;
  }
}

.title {
  @include txt-head;
  font-size: 32px;
  margin-top: 60px;
  color: $col-bgd-g5;
}

.wrapper > .title {
  &:first-child {
    margin-top: 0px;
    color: $col-bgd-2;
  }
  &:last-child {
    margin-top: 200px;
    color: $col-bgd-2;
  }
}

.event {
  @include txt-head;
  background-color: $col-bgd-g8;
  padding: 15px;
  color: $col-txt-w;
  cursor: pointer;
  &:hover {
    background-color: $col-bgd-g9;
  }
  &:active {
    background-color: $col-bgd-g8;
  }
}

.relative {
  position: relative;
}

.background {
  width: 100%;
  @include column(auto, auto, 0px);
  &-white {
    width: 80%;
    @include column(none, none, 30px);
    padding-bottom: 30px;
    background-color: $col-bgd-w;
  }
  &-dark {
    width: 80%;
    @include column(none, none, 30px);
    padding-bottom: 30px;
    background-color: $col-bgd-g3;
  }
}

.map-wrapper {
  width: 80%;
  height: 80vh;
  overflow: hidden;
}

.modal-area {
  position: relative;
  z-index: 30;
}

.text {
  text-align: left;
  word-break: keep-all;
  line-height: 1.2rem;
}
