@import "../util/style.scss";

.wrapper {
  @include row(50%, 20px, 0px);
  width: 290px;
  height: 20px;
  justify-content: space-between;
  border-radius: 5px;
}

.style {
  &-default {
    * {
      @include txt-regular();
      @include txt-14();
      color: $col-txt-g3;
    }
  }
  &-total {
    * {
      @include txt-regular();
      @include txt-14();
      color: $col-txt-w;
    }
  }
  &-detail {
    * {
      @include txt-light();
      @include txt-11();
      color: $col-txt-g5;
    }
  }
}

.frame {
  &-title {
    @include row(auto, auto, 0px);
    text-align: left;
  }
  &-value {
    @include row(auto, auto, 0px);
    justify-content: space-between;
    white-space: nowrap;
    overflow: visible;
  }
  &-left {
    @include row(auto, auto, 0px);
    text-align: left;
  }
  &-right {
    @include row(auto, auto, 0px);
    text-align: right;
  }
}

.text {
  &-default {
    @include txt-bold();
  }
  &-total {
    @include txt-bold();
    @include txt-21();
  }
  &-detail {
    @include txt-bold();
  }
}

.unit {
  &-default {
    @include txt-bold();
  }
  &-total {
    @include txt-bold();
  }
  &-detail {
    @include txt-bold();
  }
}
