@import "../util/style.scss";

.wrapper {
  position: relative;
  @include row(50%, 20px, 0px);
  justify-content: space-between;
  border-radius: 0.5rem;
  box-sizing: content-box;
  padding: 0.5rem;
  // input {
  //   font-weight: 400;
  // }
}

.style {
  &-default {
    border: 1px solid $col-g8;
    &:hover,
    &:focus-within {
      background-color: $col-txt-g95;
      border-width: 1.5px;
      padding: calc(0.5rem - 0.5px);
    }
    input::placeholder {
      color: $col-g6;
    }
    transition: border-color 300ms;
  }
  &-filled {
    background-color: $col-g95;
    &:hover,
    &:focus-within {
      background-color: $col-txt-g9;
    }
    input::placeholder {
      color: $col-g5;
    }
    transition: border-color 300ms;
  }
  &-underlined {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 1.5px solid $col-g8;
    &:hover,
    &:focus-within {
      background-color: $col-txt-g95;
      border-width: 1.5px;
      padding: 0.5rem;
    }
    input::placeholder {
      color: $col-g6;
    }
    transition: border-color 300ms;
  }
}

.status {
  &-disabled {
    pointer-events: none;
    border-color: $col-g9;
    input::placeholder {
      color: $col-g8;
    }
  }
  &-error {
    border-color: $col-r;
    border-width: 1.5px;
    &.style-default {
      padding: calc(0.5rem - 0.5px);
    }
  }
  &-success {
    border-color: $col-g;
    border-width: 1.5px;
    &.style-default {
      padding: calc(0.5rem - 0.5px);
    }
  }
}

// .sub-alarm {
//   position: absolute;
//   top: 0.2rem;
//   left: 0.2rem;
//   width: 0.3rem;
//   height: 0.3rem;
//   border-radius: 0.15rem;
//   color: $col-r;
//   &-error {
//     color: $col-r;
//   }
//   &-success {
//     color: $col-g;
//   }
// }

.frame {
  &-title {
    @include row(auto, auto, 0px);
    text-align: left;
  }
  &-value {
    @include row(auto, auto, 0px);
    justify-content: space-between;
    white-space: nowrap;
    overflow: visible;
  }
  &-left {
    @include row(auto, auto, 0px);
    text-align: left;
  }
  &-right {
    @include row(auto, auto, 0px);
    text-align: right;
  }
}

.char {
  &-required {
    color: $col-1;
  }
  &-error {
    color: $col-r;
  }
  &-success {
    color: $col-g;
  }
}

.helper-text {
  font-size: 0.75rem;
  padding-left: calc(0.5rem + 1px);
  color: $col-g5;
  transition: color 300ms;
  &.helper-status {
    &-required {
      color: $col-1;
    }
    &-disabled {
      color: $col-g8;
    }
    &-error {
      color: $col-r;
    }
    &-success {
      color: $col-g;
    }
  }
}

.label {
  font-size: 0.75rem;
  font-weight: bold;
  padding-left: calc(0.5rem + 1px);
  color: $col-g4;
  &.label-status {
    // &-required {
    //   color: $col-b;
    // }
    &-disabled {
      color: $col-g8;
    }
    // &-error {
    //   color: $col-r;
    // }
  }
}

.input-text {
  padding: 0;
  font-size: 1rem;
}

@media (max-width: 600px) {
  .wrapper {
    padding: 0.75rem 0.5rem;
  }

  .style {
    &-default {
      &:hover,
      &:focus-within {
        background-color: $col-txt-g95;
        border-width: 1.5px;
        padding: calc(0.75rem - 0.5px) calc(0.5rem - 0.5px);
      }
    }
    &-underlined {
      &:hover,
      &:focus-within {
        background-color: $col-txt-g95;
        border-width: 1.5px;
        padding: 0.75rem 0.5rem;
      }
    }
  }

  .status {
    &-disabled {
      pointer-events: none;
      border-color: $col-g9;
      input::placeholder {
        color: $col-g8;
      }
    }
    &-error {
      border-color: $col-r;
      border-width: 1.5px;
      &.style-default {
        padding: calc(0.75rem - 0.5px) calc(0.5rem - 0.5px);
      }
    }
    &-success {
      border-color: $col-g;
      border-width: 1.5px;
      &.style-default {
        padding: calc(0.75rem - 0.5px) calc(0.5rem - 0.5px);
      }
    }
  }
  .input-text {
    padding: 0;
    font-size: 1.125rem;
  }
  .label,
  .helper-text {
    font-size: 1rem;
  }
}
