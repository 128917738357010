@import "../util/style.scss";

.wrapper {
  position: relative;
  @include row(auto, auto, 0.25rem);
  padding: 0.5rem 0.5rem;
  overflow: visible;
  cursor: pointer;
  font-weight: bold;
  color: $col-g2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.shape {
  &-default {
    border-radius: 0.5rem;
  }
  &-rectangle {
    border-radius: 0;
  }
}

.color {
  &-default {
    background-color: $col-w;
    border: 1px solid $col-g8;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    &:hover {
      background-color: $col-g95;
      // @include shd-2;
    }
    &:active {
      background-color: $col-g9;
      transition: all 0.1s;
    }
    transition: all 0.3s;
  }
  &-transparent {
    &:hover {
      background-color: $col-g95;
      // @include shd-2;
    }
  }
  &-primary {
    background-color: $col-1;
    color: $col-w;
    // border: 1px solid $col-g9;
    // @include shd-1;
    &:hover {
      background-color: $col-1-hover;
      // @include shd-2;
    }
  }
  &-excel {
    background-color: #169937;
    color: $col-w;
    &:hover {
      opacity: 0.9;
      // @include shd-2;
    }
  }
  &-cad {
    background-color: #c5383f;
    color: $col-w;
    &:hover {
      opacity: 0.9;
      // @include shd-2;
    }
  }
}

.clickable {
  &.transparent {
    cursor: pointer;
    &:hover {
      background-color: $col-g95;
      // @include shd-1;
    }
  }
  &.solid {
    cursor: pointer;
    &:hover {
      background-color: $col-g95;
      // @include shd-2;
    }
  }
}

.full {
  width: 100%;
  height: 100%;
}

.icon {
  fill: $col-w;
  height: 1.1rem;
}
