@import "../util/style.scss";

.wrapper {
  @include row(none, none, 0px);
  flex-direction: row-reverse;
}

.style {
  &-default {
    * {
      @include txt-regular();
      @include txt-14();
      color: $col-txt-g3;
    }
  }
  &-total {
    * {
      @include txt-regular();
      @include txt-14();
      color: $col-txt-w;
    }
  }
  &-detail {
    * {
      @include txt-light();
      @include txt-11();
      color: $col-txt-g5;
    }
  }
}

.frame {
  &-top {
    // @include column(auto, auto, 0px);
    // justify-content: center;
    padding: 1rem 1rem;
    text-align: left;
  }
  &-content {
    width: 100%;
  }
  &-value {
    @include row(auto, auto, 0px);
    justify-content: space-between;
    white-space: nowrap;
    overflow: visible;
  }
  &-left {
    @include row(auto, auto, 0px);
    text-align: left;
  }
  &-right {
    @include row(auto, auto, 0px);
    text-align: right;
  }
  &-row-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 0.5rem;
    padding-top: 0.25rem;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    width: calc(100% + 2rem);
    &::-webkit-scrollbar {
      width: 0.25rem;
      height: 0.25rem;
      &-thumb {
        width: 10%;
        background-color: $col-bgd-g7;
        border-radius: 10px;
        &:hover {
          background-color: $col-bgd-g3;
        }
      }
    }
  }
  &-button-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 4.5rem;
    max-width: 9rem;
  }
}

.text {
  &-section {
    padding: 0.3rem 0rem;
    text-align: left;
    font-weight: $font-e;
    font-size: 1.125rem;
    transition: all 0.3s;
    line-height: 1.5rem;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &-subsection {
    padding: 0.3rem 0rem;
    text-align: left;
    font-weight: $font-b;
    font-size: 1rem;
    transition: all 0.3s;
    line-height: 1.5rem;
    white-space: inherit;
    word-break: keep-all;
    color: $col-g3;
  }
  &-title {
    padding: 0.3rem 0rem;
    font-weight: $font-b;
    font-size: 0.9375rem;
    color: $col-g5;
    white-space: nowrap;
    margin-right: 1rem;
    line-height: 1.5rem;
  }
  &-value {
    max-width: calc(100% - 45px);
    padding: 0.3rem 0rem;
    font-weight: $font-b;
    font-size: 1rem;
    color: $col-g5;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0rem;
      text-align: right;
    }
    line-height: 1.5rem;
    white-space: inherit;
    word-wrap: break-word;
    word-break: keep-all;
    color: $col-g1;
    // overflow-wrap: anywhere;
  }
  &-total {
    @include txt-bold();
    @include txt-21();
  }
  &-detail {
    @include txt-bold();
  }
  &-title-of-card {
    padding-top: 0.25rem;
    font-size: 1rem;
    font-weight: $font-e;
    &.emph {
      color: $col-w;
    }
  }
  &-subtitle-of-card {
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: $font-e;
    &.emph {
      color: $col-w;
    }
  }
  &-of-card {
    font-size: 0.875rem;
    color: $col-bgd-g3;
    font-weight: $font-r;
    &.emph {
      color: $col-bgd-g9;
    }
  }
}

.unit {
  &-default {
    @include txt-bold();
  }
  &-total {
    @include txt-bold();
  }
  &-detail {
    @include txt-bold();
  }
}
