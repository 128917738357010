@import "../util/style.scss";

.frame {
  &-image {
    width: 100%;
    height: 75%;
  }
  &-section {
    padding: 0.5rem;
  }
}
.text {
  text-align: left;
  word-break: keep-all;
  line-height: 1.2rem;
  &-section {
    font-size: 1.25rem;
    font-weight: bold;
    &-illust {
      color: $col-g5;
    }
  }
}
